require('dotenv').config();

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

const alchemyKey       = process.env.REACT_APP_ALCHEMY_URL;
const contractAddress  = process.env.REACT_APP_CONTRACT_ADDRESS;

const web3             = createAlchemyWeb3(alchemyKey);
const contract         = require("./contracts/MaxonBollocks.json");
const nftContract      = new web3.eth.Contract(contract.abi, contractAddress);

export let baseMetaURI = "https://maxon-bollocks.s3.us-west-1.amazonaws.com/m/";

export const getBaseURI = () => {
    return new Promise(resolve => {
        nftContract.methods.baseURI().call(
            (err, res) => (err ? resolve("") : resolve(res))
        )
    });
};

getBaseURI().then(uri => (baseMetaURI = uri)).catch(e => (0));

export const getMaxonCollectionOfOwner = (account) => {
    return new Promise(resolve => {
        nftContract.methods.maxonCollectionOfOwner(account).call(
            (err, res) => {
                if (err) {
                    resolve([]);
                }   else {
                    Promise.all(res.map(
                        id => fetch(baseMetaURI + id).then(response => response.json())
                    )).then(
                        collection => resolve(collection)
                    ).catch(
                        e => resolve([e])
                    )
                }
            }
        )
    });
};