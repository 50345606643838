import {Component, useEffect, useRef, useState} from "react";
import { useMetaMask } from "metamask-react";
import { getMaxonCollectionOfOwner } from "./contract.js";
import LoadingSpinner from "./LoadingSpinner";
import { HexColorPicker } from "react-colorful";
import * as PIXI from "pixi.js";
import { saveAs } from 'file-saver';

class MaxonFramer extends Component {

    constructor() {
        super();
        this.state = {
            baseURI: '',
            collectionAccount: 0,
            myCollection: []
        };
    }

    componentDidMount () {
    }

    setCollectionAccount(account) {
        if (account !== this.state.collectionAccount) {
            this.setState({
                collectionAccount: account,
                myCollection: []
            });

            if (account) {
                getMaxonCollectionOfOwner(account)
                    .then(
                        collection => {
                            this.setState({myCollection: collection})
                        })
                    .catch(e => (0));
            }
        }
    }

    render() {
        const { myCollection } = this.state;

        return (
            <div className='page-root'>
                <div className="framer">
                    <TopMenu />
                    <p className="title">
                        Mainframe
                    </p>
                    <p className="subtitle">
                        For all your Maxon digital framing needs
                    </p>

                    <div className="frame-block">
                        <Mainframe
                            collection={myCollection}
                            refreshCollection={(account) => this.setCollectionAccount(account)}
                        />
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

const Mainframe = ({collection, refreshCollection}) => {
    const { status, connect, account } = useMetaMask();

    if (status === 'connected') {
        refreshCollection(account);
    }

    switch (status) {
        case 'unavailable':
            return <p className="description">
                <b>You haven't installed MetaMask.</b><br/>
                Please go to the official MetaMask website to download it.
            </p>;
        case 'notConnected':
            return <div>
                <button onClick={connect}
                        className="submit-button">Connect
                </button>
            </div>;
        case 'connecting':
        case 'initializing':
            return <LoadingSpinner className="overlay"/>;

        case 'connected':
            return <div>
                <FrameSetup collection={collection}/>
            </div>;

        default:
            return <div />;
    }
};

const app = new PIXI.Application({width: 800, height: 600, backgroundColor: 0});
let backSprite  = new PIXI.Graphics();
let frontSprite = new PIXI.Graphics();
let frameSprite = new PIXI.Sprite();
let imageSprite = new PIXI.Sprite();
let width=800, height=600;
// PixiJS exposes a premade instance for you to use.
const loader = PIXI.Loader.shared;


const FIT_MAX = 1;
const FIT_MIN = 2;
const HPAD = 150;

const FrameSetup = ({collection}) => {
    const [activeMaxon, changeActiveMaxon] = useState(null);
    const [background, changeBackground] = useState("#000000");
    const [foreground, changeForeground] = useState("#ffffff");
    const [fitMode, changeFitMode] = useState(FIT_MAX);
    const [frame, changeFrame] = useState(-1);

    const frameList = [
        "frames/frame1.png", "frames/frame2.png",  "frames/frame3.png",  "frames/frame4.png",
        "frames/frame5.png", "frames/frame6.png",  /*"frames/frame7.png",*/  "frames/frame8.png",
        /*"frames/frame9.png",*/
        "frames/frame10.png", "frames/frame11.png", /*"frames/frame12.png",*/ "frames/frame13.png",
    ];

    const frameSizes = [
        {x:614,y:714}, {x:648,y:738}, {x:669,y:552}, {x:564,y:588},
        {x:536,y:612}, {x:529,y:587}, /*{x:614,y:682},*/ {x:596,y:701},
        /*{x:546,y:696},*/
        {x:706,y:706}, {x:715,y:679}, /*{x:656,y:730},*/ {x:592,y:720},
    ];

    const framePadding = [
        {x:162, y:150}, {x:125, y:125}, {x:76, y:95}, {x:77, y:51},
        {x:75, y:55}, {x:95, y:91}, /*{x:142, y:117},*/ {x:94, y:92},
        /*{x:101, y:95},*/
        {x:96, y:96}, {x:99, y:108}, /*{x:118, y:121},*/ {x:113, y:113},
    ];

    const ref = useRef(null);
    // On first render create our application

    frontSprite.visible = (fitMode === FIT_MIN);

    const resize = () => {
        if ( ref.current.clientWidth !== app.renderer.width || ref.current.clientHeight !== app.renderer.height ) {
            try {
                app.renderer.resize(ref.current.clientWidth, ref.current.clientHeight);
                width = ref.current.clientWidth;
                height = ref.current.clientHeight;

                drawBackground();
                sizeFrame();
                sizeImage();
            } catch (e) {
            }
        }
    };

    const setBackground = (color) => {
        try {
            app.renderer.backgroundColor = parseInt("0x" + color.substr(1) );

            drawBackground();
        }   catch (e) {}
        changeBackground(color);
    };

    const setForeground = (color) => {
        changeForeground(color);
    };

    const drawBackground = () => {
        backSprite.clear();
        backSprite.beginFill(app.renderer.backgroundColor, 1);
        backSprite.drawRect(0, 0, width, height);
    };

    const setFrame = (newFrame) => {
        if (frame !== newFrame) {
            frameSprite.texture = PIXI.Texture.from(frameList[newFrame]);
            changeFrame(newFrame);

            sizeFrame(newFrame);
            sizeImage(newFrame);
        }
    };

    const setActiveMaxon = (maxon) => {
        if ( maxon !== activeMaxon ) {
            imageSprite.texture = PIXI.Texture.from(maxon.image);
            sizeImage(frame);
            changeActiveMaxon(maxon);
        }
    };

    const sizeFrame = (frame) => {
        if ( frame >= 0 ) {
            // resize and reposition frame
            const size = frameSizes[frame];
            const scale = Math.min(width / size.x, (height-HPAD) / size.y);

            frameSprite.scale.x = scale;
            frameSprite.scale.y = scale;

            frameSprite.x = (width - size.x * scale) / 2;
            frameSprite.y = (height - size.y * scale) / 2;
        }
    };

    const sizeImage = (frame) => {
        if ( frame >= 0 ) {
            // get the inner frame size that we need to fit in
            const size = frameSizes[frame], pad = framePadding[frame];
            const W = (size.x - 2*pad.x) * frameSprite.scale.x,
                  H = (size.y - 2*pad.y) * frameSprite.scale.y;

            const scale =
                fitMode === FIT_MAX ?
                    Math.max(W / 555, H / 555) :
                    Math.min(W / 555, H / 555);

            imageSprite.scale.x = scale;
            imageSprite.scale.y = scale;

            imageSprite.x = (width  - 555 * scale) / 2;
            imageSprite.y = (height - 555 * scale) / 2;

            if (fitMode === FIT_MIN) {
                frontSprite.clear();
                frontSprite.beginFill(parseInt('0x' + foreground.substr(1)), 1);
                frontSprite.drawRect(-W/2, -H/2, W, H);
                frontSprite.x = width  / 2;
                frontSprite.y = height / 2;
            }
        }
    };

    const exportImage = () => {
        app.renderer.extract.canvas(app.stage).toBlob(function(blob){
            saveAs(blob, activeMaxon.name + ".png");
        }, 'image/png');
    };

    useEffect(() => {
        window.addEventListener('resize', () => resize());

        resize();
        setFrame(0);

        app.stage.addChild(backSprite);
        app.stage.addChild(frontSprite);
        app.stage.addChild(imageSprite);
        app.stage.addChild(frameSprite);

        ref.current.appendChild(app.view);
        app.start();

        if (!activeMaxon && collection && collection.length) {
            setActiveMaxon(collection[0]);
        }
    }, []);

    if (!activeMaxon && collection && collection.length) {
        setActiveMaxon(collection[0]);
    }

    return <div>
        <div className="selection">
                <span className="header">
                    Select your Maxon
                </span>
            {collection.map(
                maxon => <a key={maxon.name}
                            href="#"
                            className={maxon === activeMaxon ? "selected" : ""}
                            onClick={() => setActiveMaxon(maxon)}>
                    {maxon.name}
                </a>
            )}
        </div>

        <div className="frame-preview" ref={ref} />

        <div className="selection">
                <span className="header">
                    Select your Frame
                </span>
            <ul className="framelist">
                {frameList.map( (frame, index) =>
                    <li key={frame}>
                        <img src={frame} alt={frame} onClick={() => setFrame(index)} />
                    </li>
                )}
            </ul>
        </div>

        <div className="selection">
            <div className="half">
                    <span className="header">
                        Select Background color
                    </span>

                <HexColorPicker
                    color={background}
                    onChange={setBackground} />
            </div>

            <div className="half quarter">
                <span className="header">
                    Export for Twitter
                </span>

                <button onClick={exportImage}>Export</button>
            </div>

            {
                activeMaxon &&
                <div className="half quarter">
                    <span className="header">
                        High Res Download
                    </span>

                    <a href={"https://maxon-bollocks.s3.us-west-1.amazonaws.com/print/"+activeMaxon.name.substring(7)+"-print.png"} target="_blank" download>Download</a>
                </div>
            }
        </div>
    </div>
};

const TopMenu = () => {
    const { status, connect } = useMetaMask();

    return <header>
        <ul className="menu">
            <li className="logo-item">
                <img alt="Maxon Bollocks"
                     className="logo-image"
                     src="https://maxonbollocks.com/wp-content/uploads/2021/07/image_2021_07_20T10_24_41_018Z.png" />
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/abstract/">
                    About
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/blog/">
                    Story
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/tokenomics/">
                    Tokenomics
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/the-art/">
                    The Art
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/faq/">
                    FAQ
                </a>
            </li>
        </ul>
        {
            status === 'notConnected' &&
            <button onClick={connect}
                    className="connect-button">Connect
            </button>
        }
        {/*
            status === 'connected' &&
            <span className="account">Welcome, {account}</span>
        */}
    </header>
};

const Footer = () => {
    return <footer>
        <ul className="menu">
            <li className="menu-item">
                <a href="https://maxonbollocks.com/abstract/">
                    About
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/blog/">
                    Story
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xd9aaf0b1733159a4628ecf718a9a3920691891ad#code">
                    Contract Source
                </a>
            </li>
            <li className="menu-item">
                <a href="mailto:support@moba.gallery">
                    Contact
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/terms/">
                    Terms
                </a>
            </li>
        </ul>
        <div className="copyright">
            <span>© 2021 MoBA Ventures Pty Ltd. All rights reserved</span>
            <a target="_blank" rel="noreferrer" href="https://www.twitter.com/MaxonBollocks">
                <img alt="twitter" src="twitter.svg" />
            </a>
        </div>
    </footer>;
};


export default MaxonFramer;
